import React from "react";
import Helmet from "react-helmet";

import { Link } from 'gatsby';

import classNames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";

import EmptyHeader from "components/Header/EmptyHeader.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import comingSoonStyle from "assets/jss/material-kit-react/views/comingSoonStyle.jsx";

import WriteHere from "../HomePage/SubSections/WriteHere.jsx";
import EditHere from "../HomePage/SubSections/EditHere.jsx";
import FormatHere from "../HomePage/SubSections/FormatHere.jsx";
import BoxsetsHere from "../HomePage/SubSections/BoxsetsHere.jsx";



const dashboardRoutes = [];

class BetaProgramConfirmation extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      counter:5
    }
  }

  componentDidMount(){

      let that = this;
      setInterval(function() {
         let newCount = that.state.counter - 1;
         console.log(newCount);
         console.log(that.state.counter);
          that.setState({
            counter:newCount
          });
          if(that.state.counter < 0) {
              window.location = '/betaProgramDetails';
          }

      }, 1000)


}

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
      <Helmet>
        <title>BookMerlin</title>
      </Helmet>
        <EmptyHeader
          color="transparent"
          routes={dashboardRoutes}
          brand="BookMerlin"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "#009688"
          }}
          {...rest}/>
        {/*}<Parallax filter image={require("assets/img/background.jpg")} >
        <Parallax filter image={require("assets/img/backgroundShadedGray.png")}
        <Parallax filter image={require("assets/img/backgroundShaded.png")}*/}

        <Parallax filter image={require("assets/img/backgroundShaded.jpg")}
          style={{
            alignItems:'unset'
          }}
          id="invitation"
        >
          <div className={classes.container}>
            <div className={classes.section}>

            <GridContainer className={classes.gridContainer} justify='space-between' >


            <GridItem xs={12} sm={12} md={6} className={classes.gridItem}>
              {/*<div className={classes.float}>
                    <h2 className={classes.floatHeader+' '+classes.floatWrapper}>
                      <div  className={classes.headlineText}> </div>
                      <div className={classes.headlineText}>Ever ask yourself, why do I have to juggle so many copies of my manuscript?</div>
                      <div style={{
                        paddingLeft:'1em'
                      }}>
                        <div  className={classes.headlineBullet}>&bull; One for writing</div>
                        <div  className={classes.headlineBullet}>&bull; One for your editor</div>
                        <div  className={classes.headlineBullet}>&bull; One for formatting</div>
                      </div>

                    </h2>
                    <h4 className={classes.floatDescription}>

                    </h4>
              </div>*/}

              <div className={classes.sidelineMainLeft}>
                  <div classNamex={classes.headlineMain} style={{
                    fontSize:'2.0em',
                    borderx:'1px solid yellow',
                    lineHeight:'1.2',
                    fontWeight:'500'
                  }}>You're In!</div>

                  <div className={classes.headlineMainContent} style={{

                  }}>Redirecting you to the Beta Program page in {this.state.counter>0?this.state.counter:'1'} seconds</div>


                </div>




            </GridItem>
              <GridItem xs={12} sm={12} md={6}  className={classes.gridItem} >




              </GridItem>


              </GridContainer>
            </div>
            </div>
          </Parallax>
          <div className={classNames(classes.main, classes.mainRaised)}>


          </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(comingSoonStyle)(BetaProgramConfirmation);
